
export const environment = {
  production: false,
  commitHash: '43f93fc',
  commitDate: 'Wed Feb 12 11:22:01 2025 -0600',
  deployDate: '2025-02-12T17:23:34Z',
  apiUrl: 'http://localhost:8080'
};




/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
