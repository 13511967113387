<div [ngClass]="{'d-none': formLoading}">
	<router-outlet></router-outlet>
</div>

<!-- Loading -->
<div *ngIf="formLoading" class="loader text-center m-5">
  <div class="spinner-border">
	<span hidden>Loading...</span>
  </div>
</div>
