import { Component, OnInit, ApplicationRef } from '@angular/core';
import { FormsApiService } from '@app/core/api/services/forms-api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from '@app/shared/loading.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cufi.forms.ui';
  formLoading: boolean = false;

  constructor(
    public loadingService: LoadingService,
    private applicationRef: ApplicationRef
  ) {}

  ngOnInit() {
    this.loadingService.isLoading$.subscribe(async loading => {
      setTimeout(() => {
        this.formLoading = loading;
        this.applicationRef.tick();
      }, 0);
    });
  }
}
