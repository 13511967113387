import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoadingService } from '@app/shared/loading.service';



@Component({
  selector: 'app-error-modal',
  template: `
	<div class="modal-header">
	  <h4 class="modal-title">Error</h4>
	  <button type="button" class="close" (click)="close()">
		<span aria-hidden="true">&times;</span>
	  </button>
	</div>
	<div class="modal-body">
	  <pre>{{ error?.error?.message ?? 'Something went wrong...' }}</pre>
	</div>
	<div class="modal-footer">
	  <button type="button" class="btn btn-primary" (click)="close()">Close</button>
	</div>
  `,
})
export class ErrorModalComponent implements OnInit {
  error: any;

  constructor(
		private bsModalRef: BsModalRef,
		private loadingService: LoadingService
	) {}

  ngOnInit(): void {}

	close() {
		this.bsModalRef.hide();
		this.loadingService.stop();
	}
}
