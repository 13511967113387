import {APP_INITIALIZER, NgModule, ErrorHandler} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormComponent } from './components/form/form.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {EnvironmentService} from "@environments/environment.service";
import {SharedModule} from "@app/shared/shared.module";
import {LoggerModule, NgxLoggerLevel} from "ngx-logger";
import {environment} from "@environments/environment";
import { NgxPayPalModule } from 'ngx-paypal';
import { CommonModule, Location } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { YouTubePlayer } from '@angular/youtube-player';
import { GlobalErrorHandler } from "@app/shared/error-handler";
import { BsModalService } from 'ngx-bootstrap/modal';



@NgModule({
  declarations: [
    AppComponent,
    FormComponent
  ],
  imports: [
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.ERROR : NgxLoggerLevel.DEBUG,
    }),
    NgxPayPalModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    CommonModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    YouTubePlayer
  ],
  providers: [
    BsModalService,
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvironmentService, bsModalService: BsModalService) => {
        bsModalService.config.animated = true;
        bsModalService.config.backdrop = "static";
        bsModalService.config.focus = true;
        bsModalService.config.ignoreBackdropClick = true;
        bsModalService.config.keyboard = false;
        bsModalService.config.class = "modal-xl";

        return () => Promise.all<any>([envService.loadEnvironment()]);
      },
      deps: [EnvironmentService, BsModalService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
