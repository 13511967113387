/**************************************************************************
 *                                                                        *
 *                 GENERATED FILE - DO NOT MODIFY!                        *
 *                                                                        *
 *                 Update API models in API project, then                 *
 *                 regenerate with the SwaggerToTypescript tool                    *
 *                                                                        *
 **************************************************************************/

import { CufiAdminApi } from "@app/core/api/base/CufiAdminApi";
import { PagedApiResponse } from "@app/core/api/base/PagedApiResponse";
import { ApiResponse } from "@app/core/api/base/ApiResponse";
import { Observable } from "rxjs";
import { Injectable, Inject, Optional, InjectionToken } from "@angular/core";
import { PaymentTokenRequest } from "@app/core/api/models/PaymentTokenRequest";
import { PaymentRequest } from "@app/core/api/models/PaymentRequest";

@Injectable({
  providedIn: "root",
})
export class PaymentsApiService {
  private api: CufiAdminApi;

  constructor(@Inject(CufiAdminApi) api: CufiAdminApi) {
    this.api = api;
  }

  public GetUserIdToken(request: GetUserIdTokenRequest): Observable<void> {
    return this.api.GetApiCall<void>(
      "/v1/Payments/paypal/userToken",
      200,
      request.path,
      request.query,
    );
  }

  public CreateVaultSetupToken(
    request: CreateVaultSetupTokenRequest,
  ): Observable<void> {
    return this.api.GetApiCall<void>(
      "/v1/Payments/paypal/vaultSetupToken",
      200,
      request.path,
      request.query,
    );
  }

  public CreatePaymentToken(
    request: CreatePaymentTokenRequest,
  ): Observable<void> {
    return this.api.PostApiCall<void, any>(
      "/v1/Payments/paypal/paymentToken",
      200,
      request.path,
      request.query,
      request.body,
    );
  }

  public CreateCharge(request: CreateChargeRequest): Observable<void> {
    return this.api.PostApiCall<void, any>(
      "/v1/Payments/paypal/testCharge",
      200,
      request.path,
      request.query,
      request.body,
    );
  }
}

export interface GetUserIdTokenRequest {
  path?: void;
  query?: void;
  body?: void;
}

export interface CreateVaultSetupTokenRequest {
  path?: void;
  query?: void;
  body?: void;
}

export interface CreatePaymentTokenRequest {
  path?: void;
  query?: void;
  body: PaymentTokenRequest;
}

export interface CreateChargeRequest {
  path?: void;
  query?: void;
  body: PaymentRequest;
}
