import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ErrorModalComponent } from './error-modal.component'; // Create this component later


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private modalRef?: BsModalRef;

  constructor(private injector: Injector) {}

  handleError(error: any): void {
    console.error('An error occurred:', error);

    // Get the modal service from the injector
    const modalService = this.injector.get(BsModalService);

    // Open the error modal
    this.modalRef = modalService.show(ErrorModalComponent, {
      initialState: { error }
    });
  }
}
